import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  private darkModeKey = 'darkMode';

  constructor() {
    this.loadInitialDarkMode();
  }

  isDarkMode(): boolean {
    return localStorage.getItem(this.darkModeKey) === 'enabled';
  }

  enableDarkMode(): void {
    document.documentElement.classList.add('dark');
    localStorage.setItem(this.darkModeKey, 'enabled');
  }

  disableDarkMode(): void {
    document.documentElement.classList.remove('dark');
    localStorage.setItem(this.darkModeKey, 'disabled');
  }

  toggleDarkMode(): void {
    this.isDarkMode() ? this.disableDarkMode() : this.enableDarkMode();
  }

  private loadInitialDarkMode(): void {
    if (this.isDarkMode()) {
      this.enableDarkMode();
    } else {
      this.disableDarkMode();
    }
  }
}

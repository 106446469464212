import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { CustomButtonComponent } from '../../shared/custom-button/custom-button.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { ModalComponent } from '../../shared/modal/modal.component';
import { UserService } from '../../../services/user/user.service';
import { ModalFormComponent } from '../../shared/modal-form/modal-form.component';
import { ComputerDTO } from '../../../interfaces/computer-dto';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterLink, CustomButtonComponent, ModalComponent, ModalFormComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  constructor(private router: Router, private localStorageService: LocalStorageService, private userService: UserService, private fb: FormBuilder) {
    this.localStorageService.remove('login');
    this.localStorageService.remove('token');
  }

  loginForm!: FormGroup;

  isDarkMode = false;

  data: any;

  isLoading: boolean = false;
  openModal: boolean = false;
  selectUnitModal: boolean = false;
  enableTickedWindows: boolean = false;
  isSelectButtonDisabled: boolean = true;

  isAdminProfile = false;
  isProfileModalOpen: boolean = false;
  isProfileSelected = false;
  selectedProfile!: number;

  profiles: { id: number, name: string }[] = [{ id: 1, name: 'Operador' }];

  hasUnits: boolean = true;
  hasTicketWindow: boolean = true;

  unitName: string = '';
  selectedUnit: string = '';
  selectedWindow: string = '';
  errorMessage: string = 'Alguma coisa deu errado! Tente novamente';
  passwordFieldType: string = 'password';


  idTicketWindow!: number;
  units: { id: number, name: string }[] = [];
  ticketWindows: ComputerDTO[] = [];

  ngOnInit(): void {
    const darkMode = localStorage.getItem('darkMode');
    if (darkMode === 'enabled') {
      document.documentElement.classList.add('dark');
      this.isDarkMode = true;
    }

    this.loginForm = new FormGroup({
      user: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    })
    this.localStorageService.remove('login');
    this.localStorageService.remove('token');
  }

  get user() {
    return this.loginForm.get('user')!;
  }

  get password() {
    return this.loginForm.get('password')!;
  }

  async submit() {
    // verificação de formulário inválido
    if (this.loginForm.invalid) return;

    this.isLoading = true;
    const { user, password } = this.loginForm.getRawValue();

    try {
      const response = await this.userService.login(user!, password!);

      if (response.data) {
        try {
          this.loadLocalStorage(response.data[0].name, response.data[0].token);
        } catch (err) {
          console.error(err);
          this.isLoading = false;
          this.openModal = true;
        } finally {
          await this.openUnitForm();
        }
      }
    } catch (error) {
      console.error(error);
      this.errorMessage = 'Acesso Negado Usuário ou senha inválidos. Por favor, verifique suas credenciais e tente novamente...';
      this.isLoading = false;
      this.openModal = true;
    }
  }

  async openUnitForm() {
    this.isLoading = true;
    const user = this.localStorageService.get('login');
    await this.getUserByLogin(user);
    try {
      const { data } = (await this.userService.getUnits(user)) as any;
      this.units = data || [];
    } catch (error) {
      console.error('Erro ao carregar unidades:', error);
      this.units = [];
      // Trate o erro conforme necessário
    } finally {
      this.hasUnits = this.units.length !== 0;
      this.selectUnitModal = true;
      this.isLoading = false;
    }
  }

  async selectUnit(event: Event) {
    this.ticketWindows = [];
    const target = event.target as HTMLSelectElement;
    this.selectedUnit = target.value;
    const selectedUnitObj = this.units.find(unit => unit.id === Number(this.selectedUnit));
    if (selectedUnitObj) {
      this.unitName = selectedUnitObj.name;
    }
    this.selectedUnit = String(this.selectedUnit);
    this.localStorageService.set("currentUnit", this.selectedUnit);
    this.localStorageService.set("unitName", this.unitName);
    try {
      const response = await (await this.userService.getTicketsWindows(this.selectedUnit)).toPromise();
      this.ticketWindows = response.data;
    } catch (error) {
      console.error('Erro ao carregar guichês:', error);
      // Trate o erro conforme necessário
    } finally {
      this.hasTicketWindow = this.ticketWindows.length !== 0;
      if (this.hasTicketWindow) this.enableTickedWindows = true;
      this.checkSelectButtonState();
    }
  }

  selectTicketWindow(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.selectedWindow = target.value;
    
    const selectedWindowObj = this.ticketWindows.find(window => window.name === this.selectedWindow);
    if (selectedWindowObj) {
      this.idTicketWindow = selectedWindowObj.idTicketWindow;
    }
    const registerQueue = {
      1: 'all',
      2: 'private',
      3: 'plans'
    }[selectedWindowObj?.registeredQueue || 1];

    this.localStorageService.set('registerQueue', registerQueue);
    this.localStorageService.set('manualCall', selectedWindowObj?.manualCall);
    this.localStorageService.set('idTicketWindow', this.idTicketWindow);
    this.localStorageService.set("currentMachine", this.selectedWindow);
    this.userService.setTicketWindow(this.selectedWindow);
    this.checkSelectButtonState();
  }

  checkSelectButtonState(): void {
    this.isSelectButtonDisabled = !(this.selectedUnit && this.selectedWindow && this.selectedUnit !== 'Selecione a sua unidade' && this.selectedWindow !== 'Selecione o seu guichê');
  }

  loadLocalStorage(name: string, token: string) {
    this.localStorageService.set("login", name)
    this.localStorageService.set("token", token)
  }

  closeModal(type: string) {
    if (type == 'profile') {
      this.isProfileModalOpen = false;
    } else {
      this.openModal = false;
    }
  }

  closeSelectUnitModal() {
    this.selectedUnit = '';
    this.selectedWindow = '';
    this.enableTickedWindows = false;
    this.isSelectButtonDisabled = true;
    this.selectUnitModal = false;
  }

  selectProfile(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.selectedProfile = Number(target.value);
    
    this.isProfileSelected = this.selectedProfile !== 0;
  }

  setProfileSelect(profileId: number) {
    if (profileId == 1) {
      this.localStorageService.set('profile', 'Operador');
    } else {
      this.localStorageService.set('profile', 'Administrador');
    }
    this.isProfileModalOpen = false;
    this.router.navigate(['/home']);
  }

  async getUserByLogin(login: string) {
    try {
      this.data = (await this.userService.getUserByLogin(login));
      
      const admProfiles = [2027, 2083];
      let isAdmin = admProfiles.some(val => this.data.profile.includes(val));
      if (isAdmin) {
        this.isAdminProfile = true;
        this.profiles.push({ id: 2, name: 'Administrador' });
      }
    } catch (erro) {
      console.log(erro);
    } finally {
      this.localStorageService.set('profiles', this.profiles);
    }
  }

  goToDashboard() {
    if(this.isAdminProfile) {
      this.isProfileModalOpen = true;
    } else {
      this.localStorageService.set('profile', 'Operador');
      this.router.navigate(['/home']);
    } 
  }

  togglePasswordVisibility(): void {
    const passwordField = (<HTMLInputElement>document.getElementById("password")).type;
    if (passwordField === 'password') {
      this.passwordFieldType = 'text';
      (<HTMLInputElement>document.getElementById("password")).type = 'text';
    } else {
      this.passwordFieldType = 'password';
      (<HTMLInputElement>document.getElementById("password")).type = 'password';
    }
  }
}



<div class="min-h-screen px-6 py-8 lg:px-8 bg-gray-200 dark:bg-zinc-900">
    <div *ngIf="isLoading" class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
        <!-- Spinner de carregamento -->
        <div class="border-gray-300 h-12 w-12 animate-spin rounded-full border-4 border-t-green-500"></div>
    </div>

    <div class="flex justify-center">
        <section class="p-8 rounded-2xl w-96 h-full mb-4 shadow-xl bg-white dark:bg-zinc-800"
            [ngClass]="isLoading ? 'blur-lg' : ''">
            <div class="sm:mx-auto sm:w-full sm:max-w-sm text-center grid gap-y-6 mb-6">
                <img class="mx-auto w-auto" src="clinica-sim-logo.png" alt="ClinicaSim logo">
                <span class="text-black dark:text-white text-base">Acesse utilizando os dados do Tasy</span>
            </div>
            <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                <form class="space-y-6" method="post" [formGroup]="loginForm" (ngSubmit)="submit()" #formDir="ngForm">
                    <div>
                        <label for="user"
                            class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Usuário</label>
                        <div class="mt-2 relative rounded-md shadow-sm dark:bg-zinc-700">
                            <div class="absolute inset-y-0 left-0 px-4 flex items-center pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor"
                                    class="text-gray-400 dark:text-black size-5">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                </svg>
                            </div>
                            <input id="user" name="user" formControlName="user" type="text" autocomplete="user" required
                                [ngClass]="{'ring-red-500': user.invalid && formDir.submitted}"
                                placeholder="Informe o usuário"
                                class="block pl-11 w-full rounded-lg border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder-gray-500 focus:ring-1 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6">
                        </div>
                        <span *ngIf="user.invalid && formDir.submitted"
                            class="px-1 text-red-700 dark:text-red-500 text-sm">usuário
                            é obrigatório.</span>
                    </div>

                    <div>
                        <label for="password"
                            class="block text-sm font-medium leading-6 text-gray-900 dark:text-white">Senha</label>
                        <div class="mt-2 relative rounded-md shadow-sm dark:bg-zinc-700">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor"
                                    class="w-5 h-5 text-gray-400 dark:text-black">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                </svg>
                            </div>
                            <input id="password" formControlName="password" name="password" type="password"
                                autocomplete="current-password" required placeholder="Digite a senha"
                                [ngClass]="{'ring-red-500': password.invalid && formDir.submitted}"
                                class="block pl-10 w-full rounded-lg border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder-gray-500 focus:ring-1 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6">
                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center">
                                <button type="button" (click)="togglePasswordVisibility()" class="focus:outline-none">
                                    <svg *ngIf="passwordFieldType === 'password'"
                                        class="w-5 h-5 text-gray-400 dark:text-black"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                    <svg *ngIf="passwordFieldType === 'text'"
                                        class="w-5 h-5 text-gray-400 dark:text-black"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-6.228-6.228-3.65-3.65" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <span *ngIf="password.invalid && formDir.submitted"
                            class="px-1 text-red-700 dark:text-red-500 text-sm">A senha é obrigatória.</span>
                    </div>

                    <div class="pt-5">
                        <button type="submit"
                            class="flex w-full justify-center rounded-lg bg-purple-600 dark:bg-zinc-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">Entrar</button>
                    </div>
                </form>
            </div>
        </section>

        <footer class="w-full fixed bottom-0 left-0 bg-gray-800">
            <img class="w-full" src="line-footer.png" alt="line-footer">
            <div class="px-6">
                <div class="flex justify-between items-center text-white py-2">
                    <div class="">
                        <img src="logo-footer.png" alt="Logo" class="mr-3">
                        <span class="text-xs">healthcare platform</span>
                    </div>
                    <div>
                        <p class="text-xs">copyright © SiMCo. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>


<app-modal-form *ngIf="selectUnitModal">
    <div class="px-24 pb-24 pt-12 bg-white dark:bg-zinc-900 rounded-lg shadow-xl">
        <div class="flex justify-start mb-8">
            <button (click)="closeSelectUnitModal()" class="rounded-md border border-purple-600 p-1.5 dark:border-none dark:bg-white">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="#9333ea" class="size-4 text-purple-600 dark:text-purple-400">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
            </button>
        </div>
        <h2 class="text-lg font-semibold mb-6 text-center text-gray-900 dark:text-white">Por favor, selecione a unidade e o guichê que está
            trabalhando</h2>
        <form>
            <div class="mb-4">
                <label for="units" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Unidade</label>
                <select (change)="selectUnit($event)" id="units" [disabled]="units.length == 0"
                    class="mt-1 block w-full py-1 px-3 border border-gray-300 dark:border-zinc-700 bg-white dark:bg-zinc-800 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-600 sm:text-sm">
                    <option selected>Selecione a sua unidade</option>
                    <option *ngFor="let unit of units" [value]="unit.id">{{ unit.name }}</option>
                </select>
                <span *ngIf="!hasUnits" class="text-red-600 dark:text-red-400 text-xs py-1">Nenhuma unidade foi encontrada para este usuário.</span>
            </div>
            <div class="mb-6">
                <label for="window" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Guichê</label>
                <select (change)="selectTicketWindow($event)" id="window" [disabled]="!selectedUnit || !hasTicketWindow"
                    class="mt-1 block w-full py-1 px-3 border border-gray-300 dark:border-zinc-700 bg-white dark:bg-zinc-800 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-600 sm:text-sm">
                    <option selected>Selecione o seu guichê</option>
                    <option *ngFor="let ticketWindow of ticketWindows" [value]="ticketWindow.name">{{ ticketWindow.name }}</option>
                </select>
                <span *ngIf="!hasTicketWindow" class="text-red-600 dark:text-red-400 text-xs py-1">Nenhum guichê cadastrado para esta unidade.</span>
            </div>
            <button (click)="goToDashboard()" [disabled]="isSelectButtonDisabled" [ngClass]="{
                    'btn-green': !isSelectButtonDisabled,
                    'bg-gray-300 dark:bg-gray-600 cursor-not-allowed': isSelectButtonDisabled
                  }" type="button"
                class="flex justify-center gap-2 w-full rounded-lg shadow-sm p-1 items-center text-white dark:text-gray-300">
                Confirmar
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-4 dark:text-gray-300">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
            </button>
        </form>
    </div>
</app-modal-form>


<!-- Error Modal  -->
<app-modal *ngIf="openModal" (onClick)="closeModal()" [title]="'Ops...'" class="dark:bg-zinc-900">
    <div selector="body" class="mt-4 px-6">
        {{errorMessage}}
    </div>
    <div selector="footer" class="flex justify-end mt-4 px-4 py-4">
        <button (click)="closeModal()" class="bg-red-400 dark:bg-red-600 text-white px-4 py-2 rounded">Fechar</button>
    </div>
</app-modal>
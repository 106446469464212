import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, firstValueFrom, map, Observable, throwError } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  protected apiVersion = environment.apiVersion;
  protected apiURL = environment.apiUrl;

  ticketWindow!: string;
  unit!: number;

  constructor(protected httpClient: HttpClient, private localStorageService: LocalStorageService) {
    this.apiURL = this.apiURL + '/v' + this.apiVersion;
    
    if (localStorage.getItem(this.ticketWindow)) {
      this.setTicketWindow(localStorage.getItem(this.ticketWindow) as string);
    }
  }

  setTicketWindow(valor: string): void {
    localStorage.setItem(this.ticketWindow, valor);
  }

  getTicketWindow(): string {
    return localStorage.getItem(this.ticketWindow) || '';
  }

  public async login(login: string, password: string): Promise<any> {
    return await firstValueFrom(this.httpClient.post(`${this.apiURL}/Auth/`, { login, password }).pipe(
      catchError(this.handleError)
    ));
  }

  public async getUserByLogin(login: string): Promise<any> {
    return await firstValueFrom(this.httpClient.get<any>(`${this.apiURL}/User/${login}`).pipe(map(response => response.data[0]),
      catchError(this.handleError)));
  }

  public async getUnits(login: string): Promise<any> {
    return await firstValueFrom(this.httpClient.get<any>(`${this.apiURL}/User/${login}/establishments`).pipe(
      catchError(this.handleError)
    ));
  }

  public async getTicketsWindows(unit_id: string): Promise<Observable<any>> {
    return this.httpClient.get<any>(`${this.apiURL}/User/${unit_id}/ticketWindows`).pipe(
      catchError(this.handleError)
    );
  }


  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error occurred';

    if (error.error instanceof ErrorEvent) {
      // Erro do lado do cliente
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Erro do lado do servidor
      switch (error.status) {
        case 400:
          errorMessage = `Bad Request: Verifique os parâmetros enviados.`;
          break;
        case 401:
          errorMessage = `Unauthorized: Requisição não autorizada.`;
          break;
        case 404:
          errorMessage = `Not Found: O recurso solicitado não foi encontrado.`;
          break;
        case 500:
          errorMessage = `Internal Server Error: Houve um problema no servidor. Tente novamente mais tarde.`;
          break;
        default:
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          break;
      }
    }
    console.error(errorMessage);
    return throwError(() => errorMessage);
  }

}

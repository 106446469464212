<app-header></app-header>

<body class="bg-gray-200 dark:bg-zinc-900 p-4 min-h-screen min-w-full px-60 ">
    <div class="mb-6 flex justify-between items-center">
        <h2 class="text-xl font-semibold dark:text-white">Atendimento</h2>
        <button (click)="backToDashboard()" title="Volta para a tela de senhas"
            class="text-white hover:bg-purple-500 focus:outline-none bg-purple-600 dark:bg-purple-800 dark:hover:bg-purple-700 rounded p-1">
            <span class="sr-only">Back</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
            </svg>
        </button>
    </div>
    <div class="bg-white dark:bg-zinc-600 dark:text-white rounded-lg p-4">
        <div class="flex items-center gap-4 mb-2">
            <h3 class="text-black dark:text-white font-semibold">Senha: {{passwordCode}}</h3>
            <p class="text-red-500 dark:text-red-700 text-xs font-medium">{{passwordSpecialty}}</p>
        </div>
        <div class="">
            <label for="searchPatient" class="text-xs">Buscar paciente</label>
            <!-- Search by CPF -->
            <div *ngIf="showCPFSearch" class="mt-2 mb-4 flex gap-1">
                <input type="search" id="cpfSearch"
                    class="block w-full p-1 ps-4 text-sm border border-gray-300 rounded-lg bg-gray-50 placeholder:text-xs focus:outline-none focus:ring-1 focus:ring-purple-600 dark:text-black"
                    placeholder="CPF" mask="000.000.000-00" required />
                <button (click)="search('cpf')" type="button" title="Pesquisa exatamente pelo CPF informado"
                    class="inline-flex gap-2 items-center py-1 px-3 ms-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-500 dark:bg-purple-800 dark:hover:bg-purple-700">
                    Pesquisar
                    <svg class="size-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                </button>
            </div>
            <!-- Search by name -->
            <div *ngIf="!showCPFSearch" class="mt-2 mb-4 flex gap-1">
                <input type="search" id="nameSearch"
                    class="block w-full p-1 ps-4 text-sm border border-gray-300 rounded-lg bg-gray-50 placeholder:text-xs focus:outline-none focus:ring-1 focus:ring-purple-600 dark:text-black"
                    placeholder="Nome" required />
                <button (click)="search('name')" type="button"
                    title="Pesquisa por pacientes de acordo com a informação passada"
                    class="inline-flex gap-2 items-center py-1 px-3 ms-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-500 dark:bg-purple-800 dark:hover:bg-purple-700">
                    Pesquisar
                    <svg class="size-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                </button>
            </div>
            <div class="flex items-center">
                <input id="withoutCPF" type="checkbox" (change)="toggleSearch()"
                    class="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded">
                <label for="withoutCPF" class="ms-2 text-xs text-black dark:text-white">Paciente sem CPF</label>
            </div>
        </div>

        <section class="mt-4 bg-white dark:bg-zinc-600">
            <div *ngIf="patients" class="w-full rounded">
                <div (click)="selectPatient(patient.id)" *ngFor="let patient of patients"
                    class="grid grid-cols-4 gap-3 text-left rounded-md px-2 border border-purple-400 cursor-pointer mb-4 bg-purple-200 dark:bg-zinc-800">
                    <div class="">
                        <small class="text-gray-500 dark:text-gray-200">Id</small>
                        <p class="font-medium text-sm">{{ patient.id }}</p>
                    </div>
                    <div class="col-span-2">
                        <small class="text-gray-500 dark:text-gray-200">Nome</small>
                        <p class="font-medium text-sm text-nowrap text-ellipsis">{{ patient.name }}</p>
                    </div>
                    <div class="col-start-4">
                        <small class="text-gray-500 dark:text-gray-200">CPF</small>
                        <p class="font-medium text-sm">{{ patient.document }}</p>
                    </div>
                    <div class="col-start-5">
                        <small class="text-gray-500 dark:text-gray-200">Idade</small>
                        <p class="font-medium text-sm">{{ patient.age }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="hasSearched" class="flex justify-center mb-4">
                <p class="font-medium">Paciente não encontrado.</p>
            </div>
            <div class="flex items-center justify-center space-x-4 p-4">
                <button
                    class="px-2 bg-purple-600 hover:bg-purple-500 dark:bg-purple-800 dark:hover:bg-purple-700 text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed transition"
                    (click)="previousPage()" [disabled]="pageNumber === 1">
                    Anterior
                </button>
                <span class="text-gray-700">Página {{ pageNumber }}</span>
                <button
                    class="px-2 bg-purple-600 hover:bg-purple-500 dark:bg-purple-800 dark:hover:bg-purple-700 text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed transition"
                    (click)="nextPage()" [disabled]="!isNextPageAvailable">
                    Próximo
                </button>
            </div>
        </section>
    </div>
</body>

<!-- Modal -->
<app-modal *ngIf="isModalOpen" (onClick)="closeModal('schedule')"
    [title]="currentStep === 1 ? 'Agendamentos' : 'Confirmação'">
    <div selector="body" class="my-4 px-6">
        <!-- Step 1: Exibição de Agendamentos -->
        <ng-container *ngIf="currentStep === 1">
            <small class="font-medium">Agendamento do Dia para esta Unidade:</small>
            <div *ngIf="hasSchedule; else hasNoSchedule">
                <div *ngFor="let scheduleData of schedulesData" (click)="selectSchedule(scheduleData.id)"
                    class="flex gap-4 mt-2 p-2 rounded-lg hover:border-2 hover:cursor-pointer"
                    [ngClass]="{'border-2 border-purple-700': scheduleId === scheduleData.id, 'border border-gray-300': scheduleId !== scheduleData.id}">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-8">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                    </div>
                    <div>
                        <div>
                            <p class="font-semibold text-sm">{{formattedTime(scheduleData.appointmentDate)}}</p>
                            <div class="flex gap-2 items-center">
                                <p>Agenda: </p>
                                <p class="font-semibold text-sm">{{scheduleData.id}}</p>
                            </div>
                        </div>
                        <div>
                            <p class="text-sm text-purple-500 font-medium">{{scheduleData.doctorName}}</p>
                            <p class="text-xs text-gray-500">{{scheduleData.specialtyName}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #hasNoSchedule>
                <div class="flex justify-center mt-4">
                    <p class="font-semibold text-sm">Paciente sem agendamentos!</p>
                </div>
            </ng-template>
            <div class="flex justify-end mt-4 px-4 py-4 gap-4">
                <button (click)="closeModal('schedule')"
                    class="cursor-pointer px-4 py-2 rounded text-purple-500 hover:bg-purple-500 hover:text-white">Cancelar</button>
                <button *ngIf="hasSchedule" (click)="linkAndNextStep()" [disabled]="!scheduleSelected"
                    class="cursor-pointer text-white px-4 py-2 rounded"
                    [ngClass]="{'bg-purple-600 hover:bg-purple-500': scheduleSelected, 'bg-gray-200 cursor-default': !scheduleSelected}">
                    Vincular
                </button>
            </div>
        </ng-container>
        <!-- Step 2: Confirmação -->
        <ng-container *ngIf="currentStep === 2">
            <div>
                <p class="font-medium text-sm">Para finalizar este processo, retorne ao Tasy e conclua a abertura do
                    atendimento.</p>
            </div>
            <div class="flex gap-2 mt-2">
                <input type="checkbox" class="text-purple-600 bg-gray-100 border-gray-300 rounded"
                    (change)="onCheckboxChange($event)">
                <span class="font-medium text-sm">Confirmo que conclui a abertura do atendimento no Tasy</span>
            </div>
            <div class="flex justify-end mt-4 px-4 py-4 gap-4">
                <button (click)="finish()" [disabled]="!isCheckboxChecked" class="text-white px-4 py-2 rounded"
                    [ngClass]="{'bg-purple-600 hover:bg-purple-500 cursor-pointer': isCheckboxChecked, 'bg-gray-200 cursor-default': !isCheckboxChecked}">
                    Finalizar
                </button>
            </div>
        </ng-container>
    </div>
</app-modal>

<!-- InfoModal  -->
<app-modal *ngIf="isInfoModalOpen" (onClick)="closeModal('info')" [title]="modalTitle">
    <div selector="body" class="mt-4 px-6">
        {{ modalMessage }}
    </div>
    <div selector="footer" class="flex justify-end mt-4 px-4 py-4">
        <button (click)="backToDashboard()" class="bg-purple-600 text-white px-4 py-2 rounded">
            Fechar
        </button>
    </div>
</app-modal>
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, Observable, throwError } from 'rxjs';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { environment } from '../../../environments/environment';
import { PasswordDTO } from '../../interfaces/password-dto';
import { ECallCondition } from '../../enums/e-call-condition.enum';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  protected apiVersion = environment.apiVersion
  protected apiURL = environment.apiUrl

  private data: any;

  constructor(protected httpClient: HttpClient, private localStorageService: LocalStorageService) {
    this.apiURL = this.apiURL + '/v' + this.apiVersion;
  }

  setData(data: any) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  clearData() {
    this.data = null;
  }

  public async getPasswordQueue(clinicId: number): Promise<any> {
    return await firstValueFrom(this.httpClient.get<PasswordDTO>(`${this.apiURL}/Password/${clinicId}`).pipe(
      catchError(this.handleError)
    ));
  }

  public async callPassword(nrSeqPassword: number, currentMachine: string, nrSeqQueue: number, cdPassword: string, user: string, clinicId: number, idTicketWindow: number, callCondition: ECallCondition): Promise<any> {
    return await firstValueFrom(this.httpClient.post(`${this.apiURL}/Password`, { nrSeqPassword, currentMachine, nrSeqQueue, cdPassword, user, clinicId, idTicketWindow, callCondition }).pipe(
      catchError(this.handleError)
    ));
  }

  public async callNextPassword(clinicId: number, currentMachine: string, username: string, idTicketWindow: number, callCondition: ECallCondition): Promise<any> {
    return await firstValueFrom(this.httpClient.get<any>(`${this.apiURL}/SmartQueue?clinicId=${clinicId}&currentMachine=${currentMachine}&username=${username}&idTicketWindow=${idTicketWindow}&callCondition=${callCondition}`,).pipe(map(response => response.data[0]),
      catchError(this.handleError)
    ));
  }

  public async generateExpressPassword(username: string, clinicId: number): Promise<Observable<any>> {
    return this.httpClient.post(`${this.apiURL}/Password/generate-express-password`, { username, clinicId });
  }

  public async finishPassword(username: string, answered: boolean, id: number, clinicId: number): Promise<any> {
    return await firstValueFrom(this.httpClient.put(`${this.apiURL}/Password`, { username, answered, id, clinicId }).pipe(
      catchError(this.handleError)
    ));
  }

  public async linkPassword(username: string, answered: boolean, id: number, patientId: number, clinicId: number, scheduleId: number, category: string): Promise<any> {
    return await firstValueFrom(this.httpClient.put(`${this.apiURL}/Password/link-patient`, { username, answered, id, patientId, clinicId, scheduleId, category }).pipe(
      catchError(this.handleError)
    ));
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error occurred';

    if (error.error instanceof ErrorEvent) {
      // Erro do lado do cliente
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Erro do lado do servidor
      switch (error.status) {
        case 400:
          errorMessage = `Bad Request: Verifique os parâmetros enviados.`;
          break;
        case 401:
          errorMessage = `Unauthorized: Requisição não autorizada.`;
          break;
        case 404:
          errorMessage = `Not Found: O recurso solicitado não foi encontrado.`;
          break;
        case 500:
          errorMessage = `Internal Server Error: Houve um problema no servidor. Tente novamente mais tarde.`;
          break;
        default:
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          break;
      }
    }
    console.error(errorMessage);
    return throwError(() => errorMessage);
  }
}

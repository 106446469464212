import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private router: Router, private localStorageService: LocalStorageService) {}

  canActivate(): boolean {
    const isAdmin = this.localStorageService.get("profile");
    if (isAdmin == 'Administrador') {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
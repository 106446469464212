<app-header></app-header>

<body class="bg-gray-200 dark:bg-[#24292D] min-h-screen min-w-full py-4 px-32">
    <div class="w-full mx-auto rounded-lg">
        <div class="flex justify-between items-center p-4">
            <h2 class="text-lg font-semibold dark:text-gray-200 uppercase">Gerenciar Chamada Inteligente</h2>
            <button (click)="backToDashboard()"
                class="text-[#8B45DC] font-medium flex items-center gap-2 px-3 border rounded-lg border-[#8B45DC] hover:bg-[#8B45DC] hover:text-white dark:text-white dark:border-[#8B45DC] dark:bg-[#8B45DC] dark:hover:bg-[#7541b1]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
                Voltar
            </button>
        </div>
        <div class="p-4 bg-white dark:bg-[#384048] rounded-md dark:text-white">
            <div class="mb-10">
                <h3 class="font-semibold text-lg mb-2">Proporção de chamadas</h3>
                <p class="text-sm">Ajuste a proporção de chama das senhas entre fila geral e fila de prioridades</p>
            </div>
            <div class="flex gap-14 ml-6">
                <div class="flex flex-col">
                    <label class="font-semibold text-sm pb-2">Fila Prioridades</label>
                    <select (change)="onChangePriority($event)" [disabled]="!isEditing" [value]="selectedPriority"
                        class="w-36 font-medium rounded-md border p-1 text-sm dark:bg-zinc-400 dark:text-gray-50 focus-visible:border-purple-500 focus:outline-purple-500 dark:focus:outline-white">
                        <option *ngFor="let value of values" [value]="value">{{ value }}</option>
                    </select>
                </div>
                <div class="flex flex-col">
                    <label class="font-semibold text-sm pb-2">Fila Geral</label>
                    <select (change)="onChangeNormal($event)" [disabled]="!isEditing" [value]="selectedNormal"
                        class="w-36 font-medium rounded-md border p-1 text-sm dark:bg-zinc-400 dark:text-gray-50 focus-visible:border-purple-500 focus:outline-purple-500 dark:focus:outline-white">
                        <option *ngFor="let value of values" [value]="value">{{ value }}</option>
                    </select>
                </div>
                <div class="content-end">
                    <div *ngIf="!isEditing">
                        <button (click)="toggleIsEditing()" class="p-2 bg-[#CFCEDF] rounded-lg dark:">
                            <!-- Ícone de editar -->
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="#384048" class="size-4">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                            </svg>
                        </button>
                    </div>
                    <div *ngIf="isEditing" class="flex gap-2">
                        <button (click)="setQueueSettings()" class="p-2 bg-[#00AC70] stroke-white rounded-lg">
                            <!-- Ícone de salvar -->
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3"
                                class="size-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                            </svg>
                        </button>
                        <button (click)="toggleIsEditing()" class="p-2 bg-[#B4003C] stroke-white rounded-lg">
                            <!-- Ícone de cancelar -->
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3"
                                class="size-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

<!-- Info Modal  -->
<app-modal *ngIf="isModalOpen" (onClick)="closeModal()" [title]="modalTitle">
    <div selector="body" class="mt-4 px-6">
        {{ modalMessage }}
    </div>
    <div selector="footer" class="flex justify-end mt-4 px-4 py-4">
        <button (click)="closeModal()"
            class="border border-green-600 bg-green-600 text-white hover:bg-green-500 hover:text-white dark:bg-purple-800 dark:text-white dark:hover:bg-purple-700 px-4 py-2 rounded">
            Fechar
        </button>
    </div>
</app-modal>
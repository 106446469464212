import { Routes } from '@angular/router';
import { LoginComponent } from './modules/auth/login/login.component';
import { DashboardComponent } from './modules/admin/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { LinkPatientComponent } from './modules/admin/link-patient/link-patient.component';
import { ComputerSettingsComponent } from './modules/admin/settings/computer-settings/computer-settings.component';
import { AdminGuard } from './guards/admin-guard.guard';
import { QueueSettingsComponent } from './modules/admin/settings/queue-settings/queue-settings.component';

export const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'home', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'link-patient', component: LinkPatientComponent, canActivate: [AuthGuard] },
    { path: 'computer-settings', component: ComputerSettingsComponent, canActivate: [AuthGuard, AdminGuard] },
    { path: 'queue-settings', component: QueueSettingsComponent, canActivate: [AuthGuard, AdminGuard] },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

import { ChangeDetectorRef, Component } from '@angular/core';
import { HeaderComponent } from '../../shared/header/header.component';
import { CommonModule } from '@angular/common';
import { CustomButtonComponent } from '../../shared/custom-button/custom-button.component';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { UserService } from '../../../services/user/user.service';
import { interval, Subscription } from 'rxjs';
import { PasswordService } from '../../../services/password/password.service';
import { PasswordDTO } from '../../../interfaces/password-dto';
import { ModalComponent } from '../../shared/modal/modal.component';
import { Router } from '@angular/router';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ECallCondition } from '../../../enums/e-call-condition.enum';
import { DarkModeService } from '../../../services/dark-mode/dark-mode.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [HeaderComponent, CustomButtonComponent, CommonModule, ModalComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  endServiceForm!: FormGroup;

  queueName = {
    'all': 'Misto',
    'private': 'Particular',
    'plans': 'Planos'
  } as any;

  data: any;

  passwords: PasswordDTO[] = [];
  filteredPasswords: PasswordDTO[] = [];

  showMenu: boolean = false;
  isFavorited: Boolean = false;
  isExceededTime: boolean = false;
  isLoading: boolean = false;
  openToast: boolean = false;
  isBtnBlocked: boolean = false;
  showSpinner: boolean = false;
  filterActive: boolean = false;
  isModalOpen: boolean = false;
  isFinishModalOpen: boolean = false;
  isProfileModalOpen: boolean = false;
  isFinishOptionSelected = false;
  isAdminProfile = false;
  manualCall!: boolean;

  menuPosition = { top: '0px', left: '0px' };
  elapsedTimes: { [key: number]: string } = {};
  timerSubscriptions: { [key: number]: Subscription } = {};
  delayColor: { [key: number]: string } = {}; // semáforo

  initialIndex: number | null = null;
  unitCode!: number;
  finishId!: number;
  ticketId!: number;

  modalMessage!: string;
  modalTitle!: string;
  waitTimeSelected?: string;
  priorityWaitTime!: string;
  userName!: string;
  currentMachine!: string;
  toasterText!: string;
  filter: string = 'all';
  finishStatus = '';

  userProfile: string = '';
  isAdmin = false;

  interval: any;
  intervalTimeOut: any;

  connectionHub: HubConnection | undefined;

  callCondition: ECallCondition = ECallCondition.Geral;
  selectedCallPassword: PasswordDTO | undefined;
  manualPasswordSelected: PasswordDTO | undefined;
  expressPasswordData: PasswordDTO | undefined;

  constructor(
    private localStorageService: LocalStorageService,
    private userService: UserService,
    private passwordService: PasswordService,
    private darkModeService: DarkModeService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.userName = this.localStorageService.get('login');
    this.unitCode = this.localStorageService.get('currentUnit');
    this.ticketId = this.localStorageService.get('idTicketWindow');
    this.userProfile = this.localStorageService.get('profile');
    this.manualCall = this.localStorageService.get('manualCall');
    this.currentMachine = this.userService.getTicketWindow();
  }

  async ngOnInit() {
    this.isAdmin = this.userProfile !== 'Operador';
    this.filter = this.localStorageService.get('registerQueue');
    this.applyFilter();

    this.endServiceForm = new FormGroup({
      status: new FormControl(true, [Validators.required]),
    });
    await this.getPasswords();
    this.openWebSocket();
    this.startTimers();
  }

  async ngOnDestroy() {
    await this.connectionHub!.invoke('Unsubscription', String(this.unitCode));
    await this.connectionHub!.stop();
    Object.values(this.timerSubscriptions).forEach(sub => sub.unsubscribe());
  }

  async getPasswords() {
    this.isLoading = true;
    try {
      const { data } = await this.passwordService.getPasswordQueue(this.unitCode);

      this.passwords = data.map((password: PasswordDTO) => {
        const regexPrioridade80Plus = /^PR[A-Z0-9]*$/;
        const regexPrioridade = /^P[A-Z0-9]{2,3}$/;
        const regexPrioridadeSemEspecialidade = /^P[0-9]{1,3}$/;
        const isNot = /^P[A-Z]{1}[0-9]{1,3}$/;

        if (regexPrioridade80Plus.test(password.dsPassword) ||
          regexPrioridade.test(password.dsPassword) ||
          regexPrioridadeSemEspecialidade.test(password.dsPassword)) {
          password.favorite = true;
        }

        if (isNot.test(password.dsPassword)) {
          password.favorite = false;
        }

        return password;
      });

      this.applyFilter();
    } catch (error) {
      this.modalTitle = 'Atenção';
      this.modalMessage = 'Ainda não foi gerada nenhuma senha.';
      this.isModalOpen = true;
    } finally {
      this.isLoading = false;
    }
  }

  openWebSocket() {
    const options = {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () => this.localStorageService.get("token")
    };

    this.connectionHub = new HubConnectionBuilder().withUrl(environment.hubUrl, options).withAutomaticReconnect().build();
    this.connectionHub.onreconnected(() => {
      this.connectionHub!.invoke('Subscription', String(this.unitCode));
    });

    this.connectionHub.start().then(() => {
      this.connectionHub!.invoke('Subscription', String(this.unitCode));
    });

    this.connectionHub.on('Password', (result: any) => {
      if (result) {
        const { data } = JSON.parse(result);
        if (data && !this.passwords.some(p => p.id === data[0].id)) {
          const regexPrioridade80Plus = /^PR[A-Z0-9]*$/;
          const regexPrioridade = /^P[A-Z0-9]{2,3}$/;
          const regexPrioridadeSemEspecialidade = /^P[0-9]{1,3}$/;
          const isNot = /^P[A-Z]{1}[0-9]{1,3}$/;

          if (regexPrioridade80Plus.test(data[0].dsPassword) ||
            regexPrioridade.test(data[0].dsPassword) ||
            regexPrioridadeSemEspecialidade.test(data[0].dsPassword)) {
            data[0].favorite = true;
          }

          if (isNot.test(data[0].dsPassword)) {
            data[0].favorite = false;
          }

          this.passwords.push(data[0]);
          this.startTimers();
        }
      }
    });

    this.connectionHub.on('AttPassword', (result: any) => {
      if (result) {
        const { data } = JSON.parse(result);
        const index = this.passwords.findIndex(p => p.id === data[0].id);
        if (index !== -1) {
          this.passwords[index] = { ...this.passwords[index], ...data[0] };
        }
      }
    });

    this.connectionHub.on('RemovePassword', (result: any) => {
      if (result) {
        const { data } = JSON.parse(result);
        if (data && data.length > 0) {
          const idToRemove = data[0].id;
          this.passwords = this.passwords.filter(p => p.id !== idToRemove);
          this.cdr.detectChanges();
        }
      }
    });
  }

  get passwordsFiltered() {
    const minDate = new Date(0);

    let filteredList = this.passwords.filter(password => {
      const passwordFinalizationDate = new Date(password.passwordFinalizationDate);
      return passwordFinalizationDate.getTime() !== minDate.getTime();
    });

    if (this.filter === 'private') {
      filteredList = filteredList.filter(password => password.planDescription.toUpperCase() === 'PARTICULAR');
    } else if (this.filter === 'plans') {
      filteredList = filteredList.filter(password => password.planDescription.toUpperCase() !== 'PARTICULAR');
    }

    return filteredList;
  }

  setFilter(filter: string) {
    this.filter = filter;
    this.localStorageService.set('filter', this.filter);

    this.applyFilter();
  }

  applyFilter() {
    if (this.filter === 'private') {
      this.filteredPasswords = this.passwords.filter(password => password.planDescription.toUpperCase() === 'PARTICULAR');
      this.callCondition = ECallCondition.Privado;
    }
    if (this.filter === 'plans') {
      this.filteredPasswords = this.passwords.filter(password => password.planDescription.toUpperCase() !== 'PARTICULAR');
      this.callCondition = ECallCondition.Plano;
    }
    if (this.filter === 'all') {
      this.filteredPasswords = this.passwords;
      this.callCondition = ECallCondition.Geral;
    }
  }

  startTimers(): void {
    this.passwords.forEach(password => {
      const dtPasswordDate = new Date(password.passwordGenerationDate);
      this.timerSubscriptions[password.id] = interval(1000).subscribe(() => {
        const now = new Date();
        const timeDiff = now.getTime() - dtPasswordDate.getTime();
        this.elapsedTimes[password.id] = this.formatTime(timeDiff);

        // Define a cor com base no timeDiff
        const minutesDiff = timeDiff / (1000 * 60); // converte milissegundos para minutos
        if (minutesDiff < 10) {
          this.delayColor[password.id] = "green";
        } else if (minutesDiff < 25) {
          this.delayColor[password.id] = "yellow"
        } else {
          this.delayColor[password.id] = "red";
        }
      });
    });
  }

  formatTime(timeDiff: number): string {
    const absTimeDiff = Math.abs(timeDiff);
    const seconds = Math.floor((absTimeDiff / 1000) % 60);
    const minutes = Math.floor((absTimeDiff / (1000 * 60)) % 60);
    const hours = Math.floor((absTimeDiff / (1000 * 60 * 60)) % 24);

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  bringScheduleTime(hasSchedule: string, time: Date) {
    if (hasSchedule == 'N') {
      return '-';
    } else {
      const data = new Date(time);
      const horas = String(data.getHours()).padStart(2, '0');
      const minutos = String(data.getMinutes()).padStart(2, '0');
      return `${horas}:${minutos}`;
    }
  }

  onRowClick(event: MouseEvent, password: PasswordDTO) {
    event.stopPropagation();

    this.manualPasswordSelected = password;

    this.renderMenu(event);
  }

  renderMenu(event: MouseEvent) {
    if (this.selectedCallPassword && this.manualCall) {
      this.modalTitle = 'Atenção';
      this.modalMessage = 'Para chamar outra senha, finalize seu atendimento.';
      this.showMenu = false;
      this.manualPasswordSelected = undefined;
      this.isModalOpen = true;
    }
    else {
      const menuHeight = 150;
      let menuTop = event.clientY - menuHeight;
      // Ajuste caso a posição calculada seja negativa
      if (menuTop < 0) {
        menuTop = 5; // Define uma margem de segurança
      }
      this.menuPosition = { top: `${menuTop}px`, left: `${event.clientX}px` };
      this.showMenu = true;
    }
  }

  isRowSelected(password: PasswordDTO): boolean {
    return String(this.manualPasswordSelected?.id) === String(password.id);
  }

  closeMenu() {
    this.showMenu = false;
  }

  async callNextPassword() {
    this.isLoading = true;
    try {
      this.selectedCallPassword = await this.passwordService.callNextPassword(this.unitCode, this.currentMachine, this.userName, this.ticketId, this.callCondition);
    } catch (error) {
      this.modalTitle = 'Operação abortada';
      this.modalMessage = 'No momento, não há senhas disponíveis para chamada.';
      this.isModalOpen = true;
    } finally {
      this.isLoading = false;
      if (this.selectedCallPassword && this.selectedCallPassword.calls < 3) {
        this.setButtonBlockedState(true, 5000);
      } else {
        this.isBtnBlocked = true;
      }
    }
  }

  async callPassword(password: PasswordDTO | undefined) {
    this.isBtnBlocked = true;
    if (password && password.calls < 3) {
      this.openToast = true;
      clearTimeout(this.intervalTimeOut);
      this.toasterText = this.getToasterText(password.calls);
      this.intervalTimeOut = setTimeout(() => {
        this.openToast = false;
      }, 10000);

      try {
        await this.passwordService.callPassword(
          password.id,
          this.currentMachine,
          password.idQueue,
          password.dsPassword,
          this.userName,
          this.unitCode,
          this.ticketId,
          this.callCondition
        );
        password.calls++;
        if (password.calls < 3) {
          this.setButtonBlockedState(true, 5000);
        } else {
          this.isBtnBlocked = true;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async gerenateExpressPassword() {
    try {
      (await this.passwordService.generateExpressPassword(this.userName, this.unitCode)).subscribe({
        next: (response) => {
          const passwordExpress = response.data[0];
          this.expressPasswordData = passwordExpress;
          this.passwordService.setData(passwordExpress);
          this
          this.finishStatus = '3';
          this.submitPassword();
        }
      });
    } catch (error) {
      this.modalTitle = 'Atenção';
      this.modalMessage = 'Não foi possível gerar senha express.';
      this.isModalOpen = true;
    }
  }

  getToasterText(callCount: number): string {
    switch (callCount) {
      case 0:
        return 'está sendo chamada.';
      case 1:
        return 'será chamada pela última vez.';
      case 2:
        return 'foi chamada pela última vez.';
      default:
        return '';
    }
  }

  setButtonBlockedState(isBlocked: boolean, timeout: number) {
    this.isBtnBlocked = isBlocked;
    this.showSpinner = isBlocked;

    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.isBtnBlocked = !isBlocked;
      this.showSpinner = !isBlocked;
      clearInterval(this.interval);
    }, timeout);
  }

  selectPassword(password: PasswordDTO) {
    this.passwordsFiltered.map(pf => pf.isSelected = false);

    password.isSelected = true;

    this.selectedCallPassword = password;

    this.closeMenu();
    this.callPassword(password);
  }

  markAsFav(password: PasswordDTO) {
    const index = this.passwords.findIndex(p => p.id === password.id);

    if (index !== -1) {
      if (!this.passwords[index].favorite) {
        this.passwords[index].favorite = true;
        if (this.initialIndex === null) {
          // Guarda o índice inicial ao marcar como favorito pela primeira vez
          this.initialIndex = index;
        }
        const [movedPassword] = this.passwords.splice(index, 1);
        this.passwords.unshift(movedPassword);
      } else {
        this.passwords[index].favorite = false;
        if (this.initialIndex !== null) {
          // Move a senha de volta para sua posição inicial
          const [removedPassword] = this.passwords.splice(index, 1);
          this.passwords.splice(this.initialIndex, 0, removedPassword);
        }
        // Reseta o índice inicial
        this.initialIndex = null;
      }
    }

    this.closeMenu();
  }

  formatDate(scheduleDate: Date, code: string) {
    const scheduleTime = new Date(scheduleDate);
    let dateNowISO = this.passwords.find(p => p.dsPassword === code)?.hourNow!;
    let dateNow = new Date(dateNowISO);
    let diff = scheduleTime.getTime() - dateNow.getTime();
    if (diff > 1000) this.isExceededTime = true;
    const hours = String(scheduleTime.getHours()).padStart(2, '0');
    const minutes = String(scheduleTime.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  closeToast() {
    this.openToast = false;
  }

  isdisabled(): boolean {
    return !this.selectedCallPassword || this.isBtnBlocked;
  }

  disabledButton(): boolean {
    return this.selectedCallPassword !== undefined;
  }

  cantCall(password: PasswordDTO | undefined): boolean {
    if (!password) {
      return true;
    }

    if (password.calledMachine == this.currentMachine || password.calledMachine == '') {
      return false;
    }
    return true;
  }

  async finishPassword(password: PasswordDTO | undefined) {
    if (password) {
      this.isFinishOptionSelected = false;
      this.finishId = password.id;
      this.isFinishModalOpen = true;
      this.closeMenu();
    }
  }

  async setPasswordData(id: number) {
    let passwordData = this.passwords.find(p => p.id === id);
    if (passwordData == undefined) {
      this.passwordService.setData(this.expressPasswordData);
    } else {
      this.passwordService.setData(passwordData);
    }
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal(type: string) {
    if (type == 'finish') {
      this.isFinishModalOpen = false;
    } else {
      this.isModalOpen = false;
    }
  }

  selectFinishOption(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;

    if (selectedValue) {
      this.finishStatus = selectedValue;
    }

    this.isFinishOptionSelected = !!selectedValue;
  }

  async submitPassword() {
    const exec = {
      '1': () => this.handleAttend(true),
      '2': () => this.handleAttend(false),
      '3': () => this.sendToLinkPatient()
    }[this.finishStatus];

    if (exec) await exec();
  }

  async sendToLinkPatient() {
    await this.setPasswordData(this.finishId);
    this.router.navigate(['/link-patient']);
  }

  discardPassword() {
    this.finishId = Number(this.manualPasswordSelected?.id);
    this.finishStatus = '2';
    this.submitPassword();
  }

  async handleAttend(answered: boolean) {
    this.showMenu = false;
    this.isLoading = true;
    try {
      await this.passwordService.finishPassword(this.userName, answered, this.finishId, this.unitCode);
    } catch (error) {
      this.isModalOpen = true;

      this.modalTitle = 'Operação abortada';
      this.modalMessage = 'Erro ao finalizar senha.';
    } finally {
      if (this.selectedCallPassword?.id == this.finishId) this.selectedCallPassword = undefined;
      if (this.manualPasswordSelected?.id == this.finishId) this.manualPasswordSelected = undefined;
      this.isLoading = false;
      this.isModalOpen = true;
      this.isFinishModalOpen = false;

      this.modalTitle = 'Operação realizada';
      this.modalMessage = 'Senha finalizada com sucesso!';

      setTimeout(() => this.isModalOpen = false, 5000);
    }
  }

  countPasswordPriority(): number {
    return this.passwordsFiltered.filter(password => password.favorite && !password.isSelected && !password.calledMachine).length;
  }

  countPassword(): number {
    return this.passwordsFiltered.filter(password => !password.favorite && !password.isSelected && !password.calledMachine).length;
  }
}

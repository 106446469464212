<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

            <div class="relative transform overflow-hidden rounded-lg bg-white dark:bg-[#24292D] dark:text-gray-300 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <!-- Botão de fechar -->

                <div class="bg-white dark:bg-[#24292D] px-4 pb-4 pt-5 sm:p-6 sm:pb-2 border-b-gray-300 border-b">
                    <div class="flex justify-between">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-300" id="modal-title">{{title}}</h3>
                        </div>
                        <div>
                            <button class="text-gray-400 hover:text-gray-500 focus:outline-none"
                                (click)="buttonClick()">
                                <span class="sr-only">Close</span>
                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                    aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <ng-content #body></ng-content>
                </div>
                <ng-content #footer></ng-content>
            </div>
        </div>
    </div>
</div>
import { Component, Input } from '@angular/core';
import { CustomButtonComponent } from '../custom-button/custom-button.component';
import { Router } from '@angular/router'
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { DarkModeService } from '../../../services/dark-mode/dark-mode.service';
import { CommonModule } from '@angular/common';
import { UserService } from '../../../services/user/user.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CustomButtonComponent, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  constructor(private router: Router, private localStorageService: LocalStorageService, private darkModeService: DarkModeService, private userService: UserService) {
    this.userName = (this.localStorageService.get('login').toLowerCase());
    this.unitName = this.localStorageService.get('unitName');
    this.currentMachine = this.userService.getTicketWindow();
  }

  userName!: string;
  unitName!: string;
  currentMachine!: string;

  isMenuOpen = false;

  ngOnInit() { }

  toggleDarkMode(): void {
    this.darkModeService.toggleDarkMode();
  }

  isDarkMode(): boolean {
    return this.darkModeService.isDarkMode();
  }

  logout() {
    // criar lógica para deslogar
    this.localStorageService.clear();
    this.router.navigate(['/login']);
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}

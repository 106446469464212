import { Routes } from '@angular/router';
import { LoginComponent } from './modules/auth/login/login.component';
import { DashboardComponent } from './modules/admin/dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { LinkPatientComponent } from './modules/admin/link-patient/link-patient.component';

export const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'home', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'link-patient', component: LinkPatientComponent, canActivate: [AuthGuard] },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

<div class="flex items-center justify-between bg-gray-800 py-4 px-4 sticky top-0">
    <div class="flex items-center">
        <!-- Hamburger Button -->
        <button (click)="toggleMenu()" class="text-white focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
        </button>
    </div>

    <div class="flex gap-2 items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="green" viewBox="0 0 24 24" stroke-width="1.5" stroke="#1f2937"
            class="size-5">
            <path stroke-linecap="round" stroke-linejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
        </svg>
        <h4 class="text-white font-semibold capitalize">Olá, {{userName}} &nbsp;|</h4>
        <p class="text-white ">Admnistrador</p>
    </div>

    <!-- DarkMode toggle area -->
    <div class="flex items-center justify-end transition-colors">
        <label for="darkModeToggle" class="flex items-center cursor-pointer">
            <div class="relative">
                <input id="darkModeToggle" type="checkbox" class="sr-only" (change)="toggleDarkMode()"
                    [checked]="isDarkMode()">
                <div class="block w-10 h-6 bg-gray-400 dark:bg-teal-800 rounded-full"></div>
                <div class="dot absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition transform"
                    [class.translate-x-4]="isDarkMode()" [class.translate-x-0]="!isDarkMode()"></div>
            </div>
            <div class="ml-3 text-white font-medium text-sm">
                Dark Mode
            </div>
        </label>
    </div>
</div>

<div class="fixed w-64 h-full z-[99] bg-gray-700 transition-transform duration-300 ease-in-out transform"
    [class.translate-x-0]="isMenuOpen" [class.-translate-x-full]="!isMenuOpen">
    <div class="text-sm px-4 py-4">
        <h2 class="text-white pb-2">{{ unitName }}</h2>
        <h3 class="text-white">{{ currentMachine }}</h3>
    </div>
    <hr class="border-b border-gray-600" />
    <nav class="my-4 px-2 text-white text-xs">
        <a href="/home" class="block py-2 px-4 hover:bg-gray-500 rounded">Início</a>
        <a href="#" class="block py-2 px-4 hover:bg-gray-500 rounded">Gerenciar Guichês</a>
    </nav>
    <!-- Botão de Logout -->
    <div class="px-2">
        <button class="flex gap-2 text-white bg-red-800 hover:bg-red-700 p-2 w-full justify-center">Sair
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                    d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
            </svg>
        </button>
    </div>
</div>
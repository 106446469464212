import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { CustomButtonComponent } from '../../shared/custom-button/custom-button.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { ModalComponent } from '../../shared/modal/modal.component';
import { UserService } from '../../../services/user/user.service';
import { ModalFormComponent } from '../../shared/modal-form/modal-form.component';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterLink, CustomButtonComponent, ModalComponent, ModalFormComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  constructor(private router: Router, private localStorageService: LocalStorageService, private userService: UserService, private fb: FormBuilder) {
    this.localStorageService.remove('login');
    this.localStorageService.remove('token');
  }

  loginForm!: FormGroup;

  isDarkMode = false;


  isLoading: boolean = false;
  openModal: boolean = false;
  selectUnitModal: boolean = false;
  enableTickedWindows: boolean = false;
  isSelectButtonDisabled: boolean = true;

  hasUnits: boolean = true;
  hasTicketWindow: boolean = true;

  unitName: string = '';
  selectedUnit: string = '';
  selectedWindow: string = '';
  errorMessage: string = 'Alguma coisa deu errado! Tente novamente';
  passwordFieldType: string = 'password';


  idTicketWindow!: number;
  units: { id: number, name: string }[] = [];
  ticketWindows: { id: number, name: string, idTicketWindow: number }[] = [];

  ngOnInit(): void {
    const darkMode = localStorage.getItem('darkMode');
    if (darkMode === 'enabled') {
      document.documentElement.classList.add('dark');
      this.isDarkMode = true;
    }

    this.loginForm = new FormGroup({
      user: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    })
    this.localStorageService.remove('login');
    this.localStorageService.remove('token');
  }

  get user() {
    return this.loginForm.get('user')!;
  }

  get password() {
    return this.loginForm.get('password')!;
  }

  async submit() {
    // verificação de formulário inválido
    if (this.loginForm.invalid) return;

    this.isLoading = true;
    const { user, password } = this.loginForm.getRawValue();

    try {
      const response = await this.userService.login(user!, password!);

      if (response.data) {
        try {
          this.loadLocalStorage(response.data[0].name, response.data[0].token);
        } catch (err) {
          console.error(err);
          this.isLoading = false;
          this.openModal = true;
        } finally {
          await this.openUnitForm();
        }
      }
    } catch (error) {
      console.error(error);
      this.errorMessage = 'Acesso Negado Usuário ou senha inválidos. Por favor, verifique suas credenciais e tente novamente...';
      this.isLoading = false;
      this.openModal = true;
    }
  }

  async openUnitForm() {
    this.isLoading = true;
    const user = this.localStorageService.get('login');
    try {
      const { data } = (await this.userService.getUnits(user)) as any;
      this.units = data || [];
    } catch (error) {
      console.error('Erro ao carregar unidades:', error);
      this.units = [];
      // Trate o erro conforme necessário
    } finally {
      this.hasUnits = this.units.length !== 0;
      this.selectUnitModal = true;
      this.isLoading = false;
    }
  }

  async selectUnit(event: Event) {
    this.ticketWindows = [];
    const target = event.target as HTMLSelectElement;
    this.selectedUnit = target.value;
    const selectedUnitObj = this.units.find(unit => unit.id === Number(this.selectedUnit));
    if (selectedUnitObj) {
      this.unitName = selectedUnitObj.name;
    }
    this.selectedUnit = String(this.selectedUnit);
    this.localStorageService.set("currentUnit", this.selectedUnit);
    this.localStorageService.set("unitName", this.unitName);
    try {
      const response = await (await this.userService.getTicketsWindows(this.selectedUnit)).toPromise();
      this.ticketWindows = response.data;
    } catch (error) {
      console.error('Erro ao carregar guichês:', error);
      // Trate o erro conforme necessário
    } finally {
      this.hasTicketWindow = this.ticketWindows.length !== 0;
      if (this.hasTicketWindow) this.enableTickedWindows = true;
      this.checkSelectButtonState();
    }
  }

  selectTicketWindow(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.selectedWindow = target.value;
    const selectedWindowObj = this.ticketWindows.find(window => window.name === this.selectedWindow);
    if (selectedWindowObj) {
      this.idTicketWindow = selectedWindowObj.idTicketWindow;
    }
    this.localStorageService.set('idTicketWindow', this.idTicketWindow);
    this.localStorageService.set("currentMachine", this.selectedWindow);
    this.userService.setTicketWindow(this.selectedWindow);
    this.checkSelectButtonState();
  }

  checkSelectButtonState(): void {
    this.isSelectButtonDisabled = !(this.selectedUnit && this.selectedWindow && this.selectedUnit !== 'Selecione a sua unidade' && this.selectedWindow !== 'Selecione o seu guichê');
  }

  loadLocalStorage(name: string, token: string) {
    this.localStorageService.set("login", name)
    this.localStorageService.set("token", token)
  }

  closeModal() {
    this.openModal = false;
  }

  closeSelectUnitModal() {
    this.selectedUnit = '';
    this.selectedWindow = '';
    this.enableTickedWindows = false;
    this.isSelectButtonDisabled = true;
    this.selectUnitModal = false;
  }

  goToDashboard() {
    this.router.navigate(['/home']);
  }

  togglePasswordVisibility(): void {
    const passwordField = (<HTMLInputElement>document.getElementById("password")).type;
    if (passwordField === 'password') {
      this.passwordFieldType = 'text';
      (<HTMLInputElement>document.getElementById("password")).type = 'text';
    } else {
      this.passwordFieldType = 'password';
      (<HTMLInputElement>document.getElementById("password")).type = 'password';
    }
  }

  toggleDarkMode() {
    this.isDarkMode ? this.disableDarkMode() : this.enableDarkMode();
  }

  enableDarkMode() {
    document.documentElement.classList.add('dark');
    this.isDarkMode = true;
    localStorage.setItem('darkMode', 'enabled');
  }
  
  disableDarkMode() {
    document.documentElement.classList.remove('dark');
    this.isDarkMode = false;
    localStorage.setItem('darkMode', 'disabled');
  }
}



import { Component } from '@angular/core';
import { HeaderComponent } from '../../shared/header/header.component';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { UserService } from '../../../services/user/user.service';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';
import { PatientService } from '../../../services/patient/patient.service';
import { ModalComponent } from '../../shared/modal/modal.component';
import { Router } from '@angular/router';
import { PasswordService } from '../../../services/password/password.service';
import { FinishPasswordDTO } from '../../../interfaces/finish-password-dto';

@Component({
  selector: 'app-link-patient',
  standalone: true,
  imports: [HeaderComponent, CommonModule, NgxMaskDirective, ModalComponent],
  templateUrl: './link-patient.component.html',
  styleUrl: './link-patient.component.scss'
})
export class LinkPatientComponent {

  userName = '';
  unitName = '';
  clinicId!: number;
  data: any;
  showCPFSearch = true;
  formattedCPF!: string;

  patients: any;
  pageNumber: number = 1;
  pageSize: number = 10;

  currentStep: number = 1;
  isCheckboxChecked: boolean = false;

  showButtons = false;
  isNextPageAvailable: boolean = false;
  scheduleSelected = false;

  isModalOpen = false;
  isInfoModalOpen = false;
  modalTitle = '';
  modalMessage = '';

  schedulesData: any;
  scheduleId!: number;
  hasSchedule = false;
  hasSearched = false;
  passwordId!: number;
  patientId!: number;
  classification!: string;

  passwordData!: any;
  passwordSpecialty!: string;
  passwordCode!: string;

  finishPasswordData!: FinishPasswordDTO;

  constructor(private localStorageService: LocalStorageService,
    private userService: UserService,
    private patientService: PatientService,
    private router: Router,
    private passwordService: PasswordService) {
    this.userName = this.localStorageService.get('login');
    this.clinicId = this.localStorageService.get('currentUnit');
    this.unitName = this.localStorageService.get('unitName');
    this.getPasswordData();
  }

  async ngOnInit() {
    await this.getUserByLogin(this.userName);
    this.currentStep = 1;
  }

  async getPasswordData() {
    this.passwordData = await this.passwordService.getData();
    this.passwordId = this.passwordData.id;
    this.passwordSpecialty = this.passwordData.specialty ? this.passwordData.specialty : 'Express';
    this.passwordCode = this.passwordData.dsPassword;
  }

  async search(type: string) {
    this.hasSearched = false;
    this.patients = [];
    this.pageNumber = 1;
    if (type === 'cpf') {
      const cpfInput = document.getElementById('cpfSearch') as HTMLInputElement;
      this.formattedCPF = this.cpfFormatted(cpfInput.value);
      try {
        this.patients = await this.patientService.getPatientByCPF(this.formattedCPF);
        if (this.patients.length < this.pageSize) this.isNextPageAvailable = false;
        if (this.patients.length == 0) this.hasSearched = true;
      } catch (error) {
        console.error(error);
      }
    } else if (type === 'name') {
      try {
        this.loadPatients();
      } catch (error) {
        console.error(error);
      }
    }
  }

  async loadPatients() {
    const nameInput = document.getElementById('nameSearch') as HTMLInputElement;
    try {
      const response = await this.patientService.getPatientByName(nameInput.value, this.pageNumber, this.pageSize);
      this.patients = response;
      if (this.patients.length == this.pageSize) {
        this.isNextPageAvailable = true;
      } else {
        this.isNextPageAvailable = false;
      }
    } catch (error) {
      console.error('Erro ao encontrar pacientes:', error);
    }
  }

  nextPage() {
    this.pageNumber++;
    this.loadPatients();
  }

  
  previousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.loadPatients();
    }
  }

  async selectPatient(patientId: number) {
    this.patientId = patientId;
    try {
      this.schedulesData = await this.patientService.getSchedulesByPatient(patientId, this.clinicId);
      if (this.schedulesData.length != 0) this.hasSchedule = true;
      this.openModal('schedule');
    } catch (error) {
    }
  }

  selectSchedule(scheduleId: number) {
    if (this.scheduleId === scheduleId) {
      this.scheduleId = 0;
      this.scheduleSelected = false;
    } else {
      const schedule = this.schedulesData.find((p: { id: number; }) => p.id === scheduleId);
      this.scheduleId = scheduleId;
      this.scheduleSelected = true;
      this.classification = schedule?.classification;
    }
  }

  async linkAndNextStep() {
    try {
      await this.passwordService.linkPassword(this.userName, true, this.passwordId, this.patientId, Number(this.clinicId), this.scheduleId, this.classification);
    } catch (error) {
      console.log(error);
    }finally {
      this.currentStep++;
    }
  }

  finish(){
    try {
      this.passwordService.finishPassword(this.userName, true, this.passwordId, Number(this.clinicId));
    } catch (error) {
      console.log(error);
    } finally {
      this.backToDashboard();
    }
  }

  onCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.isCheckboxChecked = checkbox.checked;
  }

  cpfFormatted(cpf: string) {
    if (cpf) {
      return String(cpf.match(/\d/g)?.join(''));
    } else {
      return '';
    }
  }

  formattedTime(date: Date) {
    const data = new Date(date);
    const horas = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${horas}:${minutos}`;
    return formattedTime;
  }

  async getUserByLogin(login: string) {
    try {
      this.data = (await this.userService.getUserByLogin(login));
    } catch (erro) {
      console.log('Erro ao buscar dados do paciente.');
    }
  }

  backToDashboard() {
    this.router.navigate(['/home']);
  }

  toggleSearch() {
    this.patients = [];
    this.showCPFSearch = !this.showCPFSearch;
  }

  openModal(type: string) {
    if(type === 'schedule'){
      this.currentStep = 1;
      this.isModalOpen = true;

    } else {
      this.isInfoModalOpen = true;
    }
  }

  closeModal(type: string) {
    type === 'schedule' ? this.isModalOpen = false : this.isInfoModalOpen = false;
  }
}
